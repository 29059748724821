<script>
    const featured = [
        'Atlanta Business Chronicle’s Best Places To Work.',
        'Best and Brightest Companies To Work For®',
    ];
</script>

<section id="featured" class="Section Recognition">
    <div class="Section-bleed Recognition-content">
        <div class="Recognition-contentMain">
            <ul>
                <li>
                    <p>Recognition</p>
                    <a
                        href="https://www.inc.com/profile/adtechnacity"
                        target="_blank"
                        rel="noopener"
                    >
                        <img
                            src="./img/inc-logo-dark.svg"
                            alt="Inc. 5000"
                            class="shrink"
                        />
                    </a>
                </li>
                <li>
                    <h2 class="h1 txt-gradient">#3</h2>
                    <p>Fastest-Growing in Digital Marketing</p>
                </li>
                <li>
                    <h2 class="h1">#106</h2>
                    <p>Fastest-Growing Overall</p>
                </li>
            </ul>
        </div>
        <hr class="phone" />
        <div class="Recognition-contentAside">
            {#each featured as text, i}
                <div>
                    <figure>
                        <img
                            src="./img/featured/featured-{i + 1}.png"
                            alt={text}
                        />
                    </figure>
                    <p>{text}</p>
                </div>
            {/each}
        </div>
    </div>
</section>
