<script>
    export let class_ = 'Nav--horizontal';

    const nav = [
        { href: '#features', title: 'Our Features' },
        { href: '#success', title: 'Our Success' },
        { href: '#contact', title: 'Contact Us' },
    ];
</script>

<nav class="Nav {class_}">
    <ul class="Nav-list">
        {#each nav as { href, title }}
            <li class="Nav-item">
                <a {href} class="Nav-link">{title}</a>
            </li>
        {/each}
    </ul>
</nav>
