<script>
    const aside = [
        {
            title: 'AI-Enhanced',
            description:
                'Automated optimization and adaptive learning technology',
        },
        {
            title: 'AUDIENCE INTELLIGENCE',
            description: 'Powerful, predictive audiences in real-time',
        },
        {
            title: 'EXCLUSIVE PLACEMENTS',
            description:
                'Expertly-curated custom placements designed to fit in and stand out',
        },
    ];
</script>

<section class="About">
    <div class="About-main">
        <div>
            <h6 class="Section-eyebrow">About us -</h6>
            <h2 class="mbl">Unlock the Full Potential of Maven Intent</h2>
            <div class="mll">
                <p class="b2 mbxl-notlast">
                    Built on a transparent, intent-driven marketplace, with
                    proprietary technology that identifies the
                    highest-converting target audiences and serves media
                    tailored specifically for performance.
                </p>
                <a href="#success" class="Btn scroll-to"
                    >View Our Case Studies</a
                >
            </div>
        </div>
    </div>
    <div class="About-aside">
        <ul>
            {#each aside as { title, description }}
                <li>
                    <img
                        srcset="
                        ./img/checkmark.png    1x,
                        ./img/checkmark-2x.png 2x
                    "
                        src="./img/checkmark.png"
                        alt="Checkmark icon"
                    />
                    <div>
                        <h4>{title}</h4>
                        <p>{description}</p>
                    </div>
                </li>
            {/each}
        </ul>
    </div>
</section>
