<script>
    const overview = [
        {
            icon: 'flag',
            title: 'Challenge',
            description:
                'Reach a new qualified audience and drive incremental credit card applications, without cannibalizing existing efforts across search.',
        },
        {
            icon: 'target',
            title: 'Solution',
            description:
                'Partner with MavenIntent to reach in-market card shoppers across their transparent search marketplace.',
        },
        {
            icon: 'bookmark-check',
            title: 'Result',
            description:
                'With MavenIntent, CompareCredit.com succeeded in sourcing new audiences and drove approved card applications at a lower CPA than 32% of their google search investment. These new audiences converted at a remarkable 16% conversion rate and provided a 21% lift in approved application volume over Google and Bing alone.',
        },
    ];
</script>

<section class="Section Overview2">
    <div class="Section-bleed Overview-secondary">
        <div>
            {#each overview as { icon, title, description }}
                <div class="Overview-secondaryItem">
                    <svg class="no-shrink mvxs mrl i45s">
                        <use xlink:href="./img/sprite.svg#icon-{icon}" />
                    </svg>
                    <div>
                        <h3 class="mbs">{title}</h3>
                        <p>{description}</p>
                    </div>
                </div>
            {/each}
        </div>
        <div>
            <h6 class="Section-eyebrow">Results by the numbers -</h6>
            <ul>
                <li class="mbl">
                    <h2 class="txt-gradient-2">32%</h2>
                    <p>Lower CPA than Google Investment</p>
                </li>
                <li class="mbl">
                    <h2 class="txt-gradient-2">16%</h2>
                    <p>
                        High-value action <br />
                        conversion rate
                    </p>
                </li>
                <li>
                    <h2 class="txt-gradient-2">21%</h2>
                    <p>
                        Lift in approved <br />
                        application volume
                    </p>
                </li>
            </ul>
        </div>
    </div>
</section>
