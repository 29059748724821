<script>
    import { Router, link } from 'svelte-routing';

    const cases = [
        {
            href: '/hyundai',
            src: './img/hyundai/hyundai-logo.svg',
            size: '20.6rem',
            alt: 'Hyundai',
            description:
                'Learn how Hyundai USA and Canvas Worldwide continue to produce breakthrough performance with MavenIntent.',
        },
        {
            href: '/compare-credit',
            src: './img/compare-credit/compare-credit-logo.svg',
            size: '20.6rem',
            alt: 'Hyundai',
            description:
                'Learn how CompareCredit™ partnered with MavenIntent to generate 10,000 Approved Applications in Just 3 Months.',
        },
        {
            href: '/ring',
            src: './img/ring/ring-logo.svg',
            size: '15rem',
            alt: 'Hyundai',
            description:
                'Learn how Ring.com partnered with MavenIntent to increase sales during the competitive holiday shopping season.',
        },
    ];
</script>

<section id="success" class="Section Success">
    <h6 class="Section-eyebrow Section-bleed">Our Success -</h6>
    <div class="Section-bleed Success-stories">
        <Router>
            {#each cases as { href, src, size, alt, description }}
                <a {href} use:link class="Card" target="_blank">
                    <div class="Card-content">
                        <figure class="Card-img">
                            <img {src} {alt} style="max-width: {size}" />
                        </figure>
                        <div class="Card-txt">
                            <p>{description}</p>
                            <div
                                class="flex a-end-h a-center-v mtxs uppercase w-bold pt12 txt-secondary"
                            >
                                View case study
                                <svg class="icon mlxs">
                                    <use
                                        xlink:href="./img/sprite.svg#icon-chevron-right"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </a>
            {/each}
        </Router>
    </div>
</section>
