<script>
    const devices = ['phone', 'tablet', 'desktop'];
</script>

<section class="Section Device">
    <div class="Section-bleed">
        <div>
            <h2 class="mbl">We’re picky about the placements we choose</h2>
            <div class="mll">
                <p class="b2">
                    Our offerings include a variety of impactful desktop,
                    mobile, and video formats.
                </p>
                <span class="tablort">
                    <div class="Device-icons">
                        {#each devices as device}
                            <svg>
                                <use
                                    xlink:href="./img/sprite.svg#icon-{device}"
                                />
                            </svg>
                        {/each}
                    </div>
                </span>
            </div>
        </div>
        <div class="Device-icons tablort-noni">
            {#each devices as device}
                <svg>
                    <use xlink:href="./img/sprite.svg#icon-{device}" />
                </svg>
            {/each}
        </div>
    </div>
</section>
