<script>
    const overview = [
        {
            icon: 'flag',
            title: 'Challenge',
            description:
                'Drive new sales and maximize the impact of the company’s online marketing budgets during the competitive holiday shopping season, without cannibalizing existing efforts across search.',
        },
        {
            icon: 'target',
            title: 'Solution',
            description:
                'Promote its latest doorbell, home security, and cameras through MavenIntent’s transparent search marketplace, reaching new audiences across the web in a cost-efficient way and widening the base of prospective customers.',
        },
        {
            icon: 'bookmark-check',
            title: 'Result',
            description:
                'MavenIntent generated sales at a 16% lower CPA than the marketer’s related search campaigns and provided a 12% lift in sales over Google and Bing alone.',
        },
    ];
</script>

<section class="Section Overview2">
    <div class="Section-bleed Overview-secondary">
        <div>
            {#each overview as { icon, title, description }}
                <div class="Overview-secondaryItem">
                    <svg class="no-shrink mvxs mrl i45s">
                        <use xlink:href="./img/sprite.svg#icon-{icon}" />
                    </svg>
                    <div>
                        <h3 class="mbs">{title}</h3>
                        <p>{description}</p>
                    </div>
                </div>
            {/each}
        </div>
        <div>
            <h6 class="Section-eyebrow">Results by the numbers -</h6>
            <ul>
                <li class="mbl">
                    <h2 class="txt-gradient-2">16%</h2>
                    <p>
                        Lower CPA than the <br />marketer’s related <br />search
                        campaigns
                    </p>
                </li>
                <li class="mbl">
                    <h2 class="txt-gradient-2">12%</h2>
                    <p>
                        Lift in sales over <br />
                        Google and Bing
                    </p>
                </li>
            </ul>
        </div>
    </div>
</section>
