<script>
    import { Router, link } from 'svelte-routing';
    import Logo from '../Components/Logo.svelte';
    export let sticky = false;
</script>

<header
    id={sticky ? 'header-sticky' : 'header'}
    class="Header {sticky ? 'Header--sticky' : ''}"
>
    <div class="Header-content">
        <Router>
            <a href="/#header" use:link class="Header-logo">
                <Logo type={sticky ? 'logo' : 'logo-light-2'} />
            </a>
        </Router>
        <span />

        <a
            href="https://maven.adtechnacity.com/"
            class="Btn {sticky ? '' : 'Btn--light'}"
            target="_blank"
            rel="noopener">Log in</a
        >
    </div>
</header>
