<script>
    import HeaderSecond from '../Layout/HeaderSecond.svelte';
    import Hero from '../CompareCredit/Hero.svelte';
    import Testimonial from '../CompareCredit/Testimonial.svelte';
    import Overview from '../CompareCredit/Overview.svelte';
    import Overview2 from '../CompareCredit/Overview2.svelte';
    import WhoClient from '../CompareCredit/WhoClient.svelte';
    import Who from '../Components/Who.svelte';
    import Contact from '../Components/Contact.svelte';
</script>

<span class="Case Case--comparecredit">
    <HeaderSecond />
    <HeaderSecond sticky={true} />
    <main class="Main">
        <Hero />
        <Testimonial />
        <Overview />
        <Overview2 />
        <WhoClient />
        <Who />
        <Contact />
    </main>
</span>
