<script>
    import HeaderSecond from '../Layout/HeaderSecond.svelte';
    import Hero from '../Ring/Hero.svelte';
    import Testimonial from '../Ring/Testimonial.svelte';
    import Overview from '../Ring/Overview.svelte';
    import Overview2 from '../Ring/Overview2.svelte';
    import WhoClient from '../Ring/WhoClient.svelte';
    import Who from '../Components/Who.svelte';
    import Contact from '../Components/Contact.svelte';
</script>

<span class="Case Case--ring">
    <HeaderSecond />
    <HeaderSecond sticky={true} />
    <main class="Main">
        <Hero />
        <Testimonial />
        <Overview />
        <Overview2 />
        <WhoClient />
        <Who />
        <Contact />
    </main>
</span>
