<script>
    import { Router, Route } from 'svelte-routing';
    import Home from './Routes/Home.svelte';
    import CompareCredit from './Routes/CompareCredit.svelte';
    import Hyundai from './Routes/Hyundai.svelte';
    import Ring from './Routes/Ring.svelte';
    import Footer from './Layout/Footer.svelte';

    export let url = '';
</script>

<Router {url}>
    <Route path="/"><Home /></Route>
    <Route path="hyundai" component={Hyundai} />
    <Route path="compare-credit" component={CompareCredit} />
    <Route path="ring" component={Ring} />
</Router>
<Footer />
